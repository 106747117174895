<template>
  <div class="page page--login login">
    <template v-if="isReset">
      <h1 class="title login__title">{{ $t('forgotpassword.title') }}</h1>
      <div class="login__form" v-if="isResetSent">
        <p>{{ $t('resetpassword.checkmail') }}</p>
      </div>
      <form action="" class="login__form" autocomplete="off" v-else>
        <div class="login__form__field">
          <label>{{ $t('booking.email') }}</label>
          <input class="input login__input" type="email"
                 v-model="reset.email" :placeholder="$t('booking.email')" autocomplete="new-password"/>
        </div>
        <button class="button" @click="isReset=false">{{ $t('back') }}</button>
        <button class="button" :class="{'button--disabled':!reset.email && !reset.user}"
                type="button"
                @click="submitRequestReset">{{ $t('login.requestreset') }}
        </button>
        <p class="error login__error"><span v-if="resetError">{{ $t('forgotpassword.notfound') }}</span></p>
      </form>
    </template>
    <template v-else>
      <h1 class="title login__title">{{ $t('login.title') }}</h1>
      <form action="" class="login__form">
        <div class="login__form__field">
          <label for="user">{{ $t('login.username') }}</label>
          <input class="input login__input" id="user" type="text"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="username" :placeholder="$t('login.username')" autocomplete="new-password"/>
        </div>
        <div class="login__form__field">
          <label for="pass">{{ $t('login.password') }}</label>
          <input class="input login__input" id="pass" type="password"
                 :name="Math.round(Math.random() * Date.now())"
                 v-model="password" @keypress.enter="submitLogin" :placeholder="$t('login.password')"
                 autocomplete="new-password"/>
        </div>
        <button class="button" :class="{'disabled':isLoading||!loginActive}"
                type="button"
                @click="submitLogin">{{ $t('login.submit') }}
        </button>
        <router-link title="Registreer" to="/register">{{ $t('nav.register') }}</router-link>
        <a href="" @click.prevent="isReset=true">{{ $t('login.forgotpassword') }}</a>
        <p class="error login__error"><span v-if="errorMessage">{{ $t('login.error') }}</span></p>
      </form>
    </template>
  </div>
</template>

<script>
export default {
  name: "Login",

  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      username: "",
      password: "",

      isLoading: false,

      errorVisible: false,
      errorMessage: null,


      isReset: false,
      reset: {
        user: "",
        email: ""
      },
      resetError: false,
      isResetSent: false
    };
  },

  computed: {
    loginActive() {
      return this.username !== '' && this.password !== '';
    }
  },


  methods: {

    async submitLogin() {
      this.errorMessage = null;
      if (this.username !== '' && this.password !== '') {
        this.isLoading = true;
        try {
          await this.$store.dispatch('login', {username: this.username, password:this.password});
          this.$router.replace('/account');
        } catch (error) {
          this.errorMessage = error;
        }
        this.password = "";
        this.isLoading = false;
      }
    },

    async submitRequestReset() {
      this.resetError = false;
      try {
        const response = await this.$store.dispatch('user/recoverPassword', this.reset.email);
        if (response.status) {
          this.isResetSent = true;
        } else {
          this.resetError = true;
        }
      } catch (error) {
        this.resetError = error;
      }
    }
  }
}
</script>