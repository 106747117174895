export default {
    async validateBtw(context, payload) {
        if (!payload) {
            return {valid: false};
        }
        const url = `https://controleerbtwnummer.eu/api/validate/${payload}.json`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to validate btw.'
            );
            throw error;
        }
        return responseData;
    },

    async createUser(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/newuser?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to register new user.'
            );
            throw error;
        }
        if (responseData.status) {
            this.dispatch('login', {username: payload.user_name, password: payload.user_password});
        }
        return responseData;

    },

    async createEventsUser(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoevents/registration?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to register new events user.'
            );
            throw error;
        }
        return responseData;
    },

    async recoverPassword(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/resetpasswordemail?email=${payload}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to recover user.'
            );
            throw error;
        }
        return responseData;
    },

    async checkPasswordResetValid(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/passwordresetvalid?username=${payload.username}&code=${payload.code}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'invalid request.'
            );
            throw error;
        }
        return responseData;
    },

    async editPassword(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/changepassword?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to register new user.'
            );
            throw error;
        }
        return responseData;
    },

    async getUserData(context) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/leesuserid/${userId}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user data.'
            );
            throw error;
        }
        context.commit('setUserData', {
            userData: responseData.data
        });
    },
    clearUserData(context) {
        context.commit('setUserData', {
            userData: null
        });
        context.commit('setOrders', []);
    },

    async editUser(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/edituser?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit user.'
            );
            throw error;
        }
    },
    async newAddress(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/newaddress?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to add address to user.'
            );
            throw error;
        }
    },
    async editAddress(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/editaddress?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit address to user.'
            );
            throw error;
        }
    },
    async deleteAddress(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/deladdress?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to delete address from user.'
            );
            throw error;
        }
    },

    async getUserOrders(context, payload) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        payload.idaccount = userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/listBilloOrders.json?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user orders.'
            );
            throw error;
        }
        context.commit('setOrders', responseData.data);
        context.commit('setPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    },
    async addEcode(context, payload){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        payload.idaccount = userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billousers/linkorder?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to link order e-code.'
            );
            throw error;
        }
    }
};