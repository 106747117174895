<template>
  <div class="header">
    <div class="header__top">
      <router-link to="/faq">{{ $t('nav.faq') }}</router-link>
      <router-link to="/contact">{{ $t('nav.contact') }}</router-link>
      <!--<router-link to="/jobs">{{$t('nav.jobs')}}</router-link>-->
      <a :href="$t('partner.link')" target="_blank">{{ $t('partner.title') }}</a>
      <router-link to="/account" v-if="userData">
        {{ `${userData.user.firstname} ${userData.user.lastname}` }}
        <fa-icon icon="user-circle"></fa-icon>
      </router-link>
      <router-link to="/login" v-else>{{ $t('nav.login') }}</router-link>
      <LanguageSelector class="header__top__dropdown"></LanguageSelector>
    </div>
    <div class="header__bottom">
      <div class="header__bottom__bg"></div>
      <div class="header__bottom__content">
        <div class="header__bottom__content__menu">
          <router-link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="123" height="37" xmlns:xlink="http://www.w3.org/1999/xlink">
              <defs>
                <path id="a" d="M0 36.22h122.966V.276H0z"/>
              </defs>
              <g fill="none" fill-rule="evenodd">
                <path fill="#000" d="M14.326 30.845h7.165v-23.2h-7.16"/>
                <g transform="translate(0 .127)">
                  <path fill="#000"
                        d="M35.82 17.54v8.494c0 .29-.362.426-.54.2-1.028-1.303-2.806-2.306-4.833-2.306-2.02 0-3.796.724-4.823 2.15-.173.24-.55.11-.55-.188V7.52c2.97 0 5.652 1.166 7.6 3.053 1.945 1.884 3.147 4.493 3.147 6.966zM25.074 7.52H21.49c0-2-1.603-3.623-3.58-3.623-1.98 0-3.584 1.622-3.584 3.622h-3.582c0-4 3.208-7.25 7.165-7.25 3.95 0 7.16 3.24 7.16 7.24"/>
                  <path fill="#000"
                        d="M30.447 32.6c-.99 0-1.79-.813-1.79-1.812v-.06c.007-.225.055-.44.137-.638.27-.653.91-1.113 1.653-1.113.745 0 1.384.46 1.654 1.113.09.197.13.413.14.638v.06c0 1-.8 1.81-1.79 1.81m0-7.243c-2.96 0-5.36 2.418-5.37 5.408v.025c0 2.998 2.41 5.433 5.38 5.433 2.97 0 5.374-2.43 5.374-5.43v-.02c-.01-2.99-2.41-5.41-5.373-5.41M0 17.54v8.494c0 .29.363.426.543.2 1.026-1.303 2.805-2.306 4.83-2.306 2.022 0 3.797.724 4.824 2.15.173.24.55.11.55-.188V7.52c-2.97 0-5.652 1.166-7.598 3.053C1.2 12.457 0 15.066 0 17.54"/>
                  <path fill="#000"
                        d="M3.583 30.788v-.06c.007-.225.055-.44.138-.638.27-.653.91-1.113 1.66-1.113.75 0 1.39.46 1.66 1.113.08.197.13.413.137.638v.06c0 1-.8 1.81-1.79 1.81s-1.79-.81-1.79-1.81M0 30.763v.025c0 2.998 2.404 5.433 5.374 5.433 2.97 0 5.374-2.43 5.374-5.43v-.02c-.012-2.99-2.413-5.41-5.374-5.41-2.962 0-5.362 2.42-5.374 5.41"/>
                  <path fill="#000"
                        d="M51.948 23.476v6.93h5.79c2.01 0 3.037-1.825 3.037-3.527 0-1.62-1.026-3.41-3.16-3.41h-5.667zm4.72-5.023c1.56 0 2.63-1.245 2.63-2.947 0-1.785-.904-2.988-2.67-2.988h-4.68v5.935h4.72zm5.092 2.034c3.736.955 5.337 4.192 5.337 6.517 0 4.73-3.407 8.757-9.032 8.757H45.913V7.17h11.332c5.09 0 8.374 3.072 8.374 7.97 0 1.868-.95 4.233-3.86 5.354z"/>
                  <mask id="b" fill="#fff">
                    <use xlink:href="#a"/>
                  </mask>
                  <path fill="#000"
                        d="M71.365 35.762h5.665V16.378h-5.665v19.384zm2.832-29.927c1.93 0 3.49 1.453 3.49 3.362 0 1.91-1.56 3.404-3.49 3.404-1.93 0-3.572-1.49-3.572-3.4s1.642-3.36 3.572-3.36zm8.087 29.927h5.706V5.836h-5.706m10.96 29.926h5.707V5.836h-5.7m24.1 20.17c0-2.614-1.97-4.4-4.35-4.4-2.34 0-4.31 1.786-4.31 4.4 0 2.7 1.97 4.443 4.31 4.443 2.386 0 4.356-1.75 4.356-4.45m-14.287 0c0-6.1 4.516-10.05 9.935-10.05 5.42 0 9.977 3.943 9.977 10.044 0 6.102-4.556 10.087-9.976 10.087s-9.935-3.984-9.935-10.086"
                        mask="url(#b)"/>
                </g>
              </g>
            </svg>
          </router-link>
          <div class="header__bottom__content__menu__main">
            <router-link to="/">{{ $t('nav.home') }}</router-link>
            <router-link to="/events">{{ $t('nav.events') }}</router-link>
            <router-link to="/about">{{ $t('nav.about') }}</router-link>
            <!--<router-link to="/customers">{{ $t('nav.customers') }}</router-link>-->
          </div>
        </div>
        <h1 class="header__bottom__content__title" v-if="$route.name">
          {{ $t('header.title.' + $route.name.toLowerCase()) }}</h1>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "./LanguageSelector";

export default {
  name: "Header",

  components: {
    LanguageSelector
  },

  computed: {
    userData(){
      return this.$store.getters['user/userData'];
    }
  }
}
</script>