export default {
    userData(state){
        return state.userData;
    },

    orders(state){
        return state.orders;
    },

    ordersPages(state){
        return state.ordersPages;
    },

    hasOrders(state){
        return state.orders && state.orders.length;
    }
};