export default{
    airports(state){
        return state.airports;
    },
    hasAirports(state){
        return state.airports && state.airports.length;
    },
    price(state){
        return state.price;
    }
}