<template>
    <div class="popup">
        <div class="popup__wrapper">
            <p class="popup__title">{{$t('booking.addcoupon')}}</p>
            <div class="popup__body">
                <input type="text" class="input" :class="{'error':couponCode && !couponValid}" v-model="couponCode">
            </div>
            <div class="popup__controls">
                <button class="button button--decline" @click="$emit('modal-close')">{{$t('cancel')}}</button>
                <button class="button button--accept" :class="{'button--disabled':!couponValid || !couponCode}" @click="onCouponConfirmed">{{$t('add')}}</button>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "BookingCoupon",

        props:{
            coupons: {required:true,type:Array}
        },

        data(){
            return {
                couponValid: false,
                couponCode: "",
                couponData: null,
                couponTimeout: null
            };
        },

        beforeDestroy() {
            clearTimeout(this.couponTimeout);
        },

        watch:{
            couponCode() {
                clearTimeout(this.couponTimeout);
                if(!this.couponCode){
                    return;
                }
                this.couponTimeout = setTimeout(async() => {
                  const res = await this.$store.dispatch('booking/checkCoupon',this.couponCode);
                  this.couponValid = res.status && (this.coupons.indexOf(this.couponCode.toString())<0);
                  this.couponData = res.status ? res.data : null;
                }, 500);
            },
        },

        methods:{
            onCouponConfirmed(){
                this.coupons.push(this.couponCode.toString());
                this.couponCode = "";
                this.$emit('modal-close');
            }
        }
    }
</script>