export default{
    async getAirports(context){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/airports?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch airports.'
            );
            throw error;
        }
        context.commit('setAirports', responseData.data);
        return responseData.data;
    },

    async getStreets(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/streets/zoekstraten/${payload.postal}/${payload.street}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch streets.'
            );
            throw error;
        }
        return responseData;
    },

    async checkCoupon(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoprice/checkcoupon?coupon=${payload}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
                responseData.error_description || 'Failed to check coupon.'
            );
            throw error;
        }
        return responseData;
    },

    async postPriceParams(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoprice?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to calculate price.'
            );
            throw error;
        }
        context.commit('setPrice', responseData.data);
        return responseData.data;
    },

    async saveBooking(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoprice/neworder?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to book new order.'
            );
            throw error;
        }
        return responseData.ID;
    },

    async handleInvoice(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/invoiceorderok/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed handle invoicing.'
            );
            throw error;
        }
        return responseData;
    },

    async handleFreeOrder(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/freeorderok/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed handle free order.'
            );
            throw error;
        }
        return responseData;
    },

    async getPaymentMethod(context, payload) {
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/payment/checkpaymentmethode/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to check payment method.'
            );
            throw error;
        }
        return responseData.data;
    },

    async getBooking(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/ordersbillo/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to get booking.'
            );
            throw error;
        }
        return responseData.data;
    }
}