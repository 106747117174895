import Vue from 'vue';
import App from './App.vue';
import router from './router';

import {store} from "./store";

Vue.config.productionTip = false;

//localisation
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import { languages, defaultLocale } from './i18n/index.js'

const messages = Object.assign(languages);
const dateTimeFormats = {
  'nl' : {
    hes:{
      year: '2-digit', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    }
  },
  'fr' : {
    hes:{
      year: '2-digit', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    }
  },
  'en': {
    hes:{
      year: '2-digit', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: false
    }
  }
};
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'nl',
  messages,
  dateTimeFormats
});

//font awesome
import {library} from "@fortawesome/fontawesome-svg-core";
import {faStar, faTimes, faUserCircle, faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(
    faStar,
    faTimes,
    faUserCircle,
    faEdit,
    faEye
);

Vue.component('fa-icon', FontAwesomeIcon);

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
