<template>
    <div class="languages">
        <button class="languages__btn">{{$i18n.locale}}</button>
        <div class="languages__content">
            <div class="languages__content__lang"
                 v-for="item in $i18n.availableLocales" :key="item" @click="$i18n.locale=item">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LanguageSelector"
    }
</script>