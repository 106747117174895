<template>
  <div class="page page--home home">
    <h1>{{ $t('home.title') }}</h1>
    <div v-html="$t('home.text')"></div>
    <Booking v-if="token" :qtalic="talic" :isAgent="travelagent" :quid="uid" :qtaEmail="taEmail" :qtaName="taName"
             :partner="partner"></Booking>
  </div>
</template>

<script>
import Booking from "@/components/booking/Booking";

export default {
  name: 'Home',
  components: {
    Booking
  },

  data() {
    return {
      talic: this.$route.query.TALIC,
      uid: this.$route.query.UID,
      taEmail: this.$route.query.TAEMAIL,
      taName: this.$route.query.TANAME,
      partner: this.$route.query.PARTNER,
      travelagent: this.$route.query.AGENT
    };
  },

  computed: {
    token(){
      return this.$store.getters.token;
    }
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  methods: {
    logoutUser() {
      this.$store.dispatch('logout');
    }
  }
}
</script>
