export default{
    async getFeedback(context, payload){
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        payload.idaccount = userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/feedback/findFeedback?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch feedback.'
            );
            throw error;
        }
        context.commit('setFeedback', responseData.data);
        context.commit('setFeedbackPages', Math.ceil(responseData.metadata.count / responseData.metadata.PageSize));
    }
}