import Vue from 'vue'
import VueRouter from 'vue-router'
//pages
import Home from '../views/Home';
import Login from "../views/Login";

import {store} from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
            title: 'Billo web - Home',
            metaTags: [
                {
                    name: 'description',
                    content: 'The home page of Billo web. Start booking now.'
                },
                {
                    property: 'og:description',
                    content: 'The home page of Billo web. Start booking now.'
                }
            ]
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            isLogin: true,
            requiresUnauth: true,
            title: 'Billo web - Login',
            metaTags: [
                {
                    name: 'description',
                    content: 'The login page of Billo web.'
                },
                {
                    property: 'og:description',
                    content: 'The login page of Billo web.'
                }
            ]
        }
    },
    {
        path: '/register',
        name: 'Register',
        meta: {
            requiresUnauth: true,
            title: 'Billo web - Register',
            metaTags: [
                {
                    name: 'description',
                    content: 'The register page of Billo web. Create your account and check on all your bookings.'
                },
                {
                    property: 'og:description',
                    content: 'The register page of Billo web. Create your account and check on all your bookings.'
                }
            ]
        },

        component: () => import('../views/Register')
    },
    {
        path: '/about',
        name: 'About',

        meta: {
            title: 'Billo web - About',
            metaTags: [
                {
                    name: 'description',
                    content: 'The about page of Billo web. Learn more about our services.'
                },
                {
                    property: 'og:description',
                    content: 'The about page of Billo web. Learn more about our services.'
                }
            ]
        },

        component: () => import('../views/About')
    },
    {
        path: '/account',
        name: 'Account',
        meta: {
            requiresAuth: true,
            title: 'Billo web - Account',
            metaTags: [
                {
                    name: 'description',
                    content: 'The account page of Billo web. Check your addresses and bookings.'
                },
                {
                    property: 'og:description',
                    content: 'The account page of Billo web. Check your addresses and bookings.'
                }
            ]
        },

        component: () => import('../views/Account')
    },
    {
        path: '/contact',
        name: 'Contact',

        meta: {
            title: 'Billo web - Contact',
            metaTags: [
                {
                    name: 'description',
                    content: 'The contact page of Billo web. Contact us anytime.'
                },
                {
                    property: 'og:description',
                    content: 'The contact page of Billo web. Contact us anytime.'
                }
            ]
        },

        component: () => import('../views/Contact')
    },
    {
        path: '/customers',
        name: 'Customers',

        meta: {
            title: 'Billo web - Customers',
            metaTags: [
                {
                    name: 'description',
                    content: 'The customers page of Billo web. Discover feedback given by our clients.'
                },
                {
                    property: 'og:description',
                    content: 'The customers page of Billo web. Discover feedback given by our clients.'
                }
            ]
        },

        component: () => import('../views/Customers')
    },
    {
        path: '/events',
        name: 'Events',

        meta: {
            title: 'Billo web - Events',
            metaTags: [
                {
                    name: 'description',
                    content: 'The events page of Billo web. Easily book transportation to your event now.'
                },
                {
                    property: 'og:description',
                    content: 'The events page of Billo web. Easily book transportation to your event now.'
                }
            ]
        },
        component: () => import('../views/Events')
    },
    {
        path:'/events/:organiser',
        name:'EventOrganiser',
        meta: {
            title: 'Billo web - Events',
            metaTags: [
                {
                    name: 'description',
                    content: 'The events page of Billo web. Easily book transportation to your event now.'
                },
                {
                    property: 'og:description',
                    content: 'The events page of Billo web. Easily book transportation to your event now.'
                }
            ]
        },
        component: () => import('../views/Events')
    },
    {
        path: '/events-register',
        name: 'EventsRegister',
        meta: {
            title: 'Billo web - Register Organiser',
            metaTags: [
                {
                    name: 'description',
                    content: 'The organiser registration page of Billo web. Create your my-events account.'
                },
                {
                    property: 'og:description',
                    content: 'The organiser registration page of Billo web. Create your my-events account.'
                }
            ]
        },
        component: () => import('../views/EventsRegister')
    },
    {
        path: '/faq',
        name: 'Faq',
        meta: {
            title: 'Billo web - FAQ',
            metaTags: [
                {
                    name: 'description',
                    content: 'The FAQ page of Billo web. An answer to all your questions.'
                },
                {
                    property: 'og:description',
                    content: 'The FAQ page of Billo web. An answer to all your questions.'
                }
            ]
        },
        component: () => import('../views/Faq')
    },
    {
        path: '/jobs',
        name: 'Jobs',

        meta: {
            title: 'Billo web - Jobs',
            metaTags: [
                {
                    name: 'description',
                    content: 'The jobs page of Billo web. We are looking for new blood.'
                },
                {
                    property: 'og:description',
                    content: 'The jobs page of Billo web. We are looking for new blood.'
                }
            ]
        },

        component: () => import('../views/Jobs')
    },
    {
        path: '/order-canceled',
        name: 'OrderCanceled',
        title: 'Billo web - Order canceled',

        component: () => import('../views/OrderCanceled')
    },
    {
        path: '/order-confirmed',
        name: 'OrderConfirmed',
        title: 'Billo web - Order confirmed',

        component: () => import('../views/OrderConfirmed')
    },
    {
        path: '/order-failed',
        name: 'OrderFailed',
        title: 'Billo web - Order failed',

        component: () => import('../views/OrderFailed')
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        title: 'Billo web - Reset password',
        component: () => import('../views/ResetPassword')
    },
    {
        path: '/terms',
        name: 'Terms',
        title: 'Billo web - terms & conditions',

        component: () => import('../views/Terms')
    },
    {
        path: '/phone',
        name: 'Phone',
        title: 'Billo web - Pay phone terms',

        component: () => import('../views/Phone')
    },
    {
        path: '/pricing',
        name: 'Pricing',
        title: 'Billo web - pricing',

        component: () => import('../views/Pricing')
    },

    //booking modifiers
    {
        path: '/tui',
        name:'TUI',
        title: 'Billo web - TUI',
        redirect: {name:'Home',query:{TALIC:'TUI001',TANAME:'TUI',PARTNER:'TUI'}}
    },
    {
        path: '/del-tour',
        name:'Deltour',
        title: 'Billo web - Del-Tour',
        redirect: {name:'Home',query:{TALIC:'DT001',TANAME:'DEL-TOUR',PARTNER:'DEL-TOUR'}}
    },
    {
        path: '/deltour',
        redirect: {name:'Deltour'}
    },
    {
        path: '/davidsfonds',
        name:'Davidsfonds',
        title: 'Billo web - Davidsfonds',
        redirect: {name:'Home',query:{TALIC:'D0001',TANAME:'Davidsfonds',PARTNER:'DAVIDSFONDS'}}
    },

    {
        path: '*',
        name: 'NotFound',
        title: 'Billo web - 404',

        component: () => import('../views/NotFound')
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {

    //meta
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    document.title = nearestWithTitle?nearestWithTitle.meta.title:"Billo web";
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    if(nearestWithMeta) {
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');
            Object.keys(tagDef).forEach(key => {
                tag.setAttribute(key, tagDef[key]);
            });
            tag.setAttribute('data-vue-router-controlled', '');
            return tag;
        }).forEach(tag => document.head.appendChild(tag));

    }
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/login');
    } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
        next('/account');
    } else {
        next();
    }
});

export default router;
