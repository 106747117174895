export default{
    async getEvents(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoevents/leesevents?taal=${payload.lang}&userid=${payload.user}&location_id=${payload.location}&access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch events.'
            );
            throw error;
        }
        context.commit('setEvents', responseData.DATA);
    },

    async getEventData(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoevents/leeseventdata/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch events.'
            );
            throw error;
        }
        return responseData.data;
    },

    async getEventOrganiser(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoeventaccounts/leesuserid/${payload}?access_token=${token}`;
        const response = await fetch(url);
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch event organiser'
            )
            throw error;
        }
        return responseData.data;
    },

    async postEventPriceParams(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoevents/berekenprijs?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to calculate event price.'
            );
            throw error;
        }
        context.commit('setEventPrice', responseData.data);
        return responseData.data;
    },

    async saveEventBooking(context, payload){
        const token = context.rootGetters.token;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/billoevents/neworder?access_token=${token}`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to book event.'
            );
            throw error;
        }
        return responseData.ID;
    }
}