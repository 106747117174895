export default {
    setUserData(state,payload){
        state.userData = payload.userData;
    },

    setOrders(state,payload){
        state.orders = payload;
    },

    setPages(state, payload){
        state.ordersPages = payload;
    }
};