<template>
  <div id="app">
    <Header></Header>
    <router-view/>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  components: {
    Header,
    Footer
  },

  data(){
    return {
      versionTimeout: null
    }
  },

  created() {
    if(this.$route.query.AGENT){
      this.$store.dispatch('logout');
    }
    this.$store.dispatch('tryLogin');
    this.initVersionWatcher();
  },

  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },

  beforeDestroy(){
    clearTimeout(this.versionTimeout);
  },

  methods: {
    initVersionWatcher() {
      const current = parseInt(process.env.VERSION.replaceAll(".",""));
      const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/version?name=BILLOWEB`;
      fetch(url).then(res => res.json().then(data => {
        if(data.status && data.data && data.data.versie){
          const latest = parseInt(data.data.versie.replaceAll(".",""));
          if(latest > current && data.data.hotfix){
            console.log('update required');
            location.reload();
          }
        }
        else{
          console.warn("could not fetch active version.");
        }
      }));
      this.versionTimeout = setTimeout(()=>{
        this.initVersionWatcher();
      }, 2*60*1000);
    }
  }
}
</script>

<style lang="scss">
@import 'assets/sass/app';
</style>
