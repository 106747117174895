import Vue from 'vue';
import VueX from 'vuex';
Vue.use(VueX);

import authModule from './modules/auth';
import userModule from './modules/user';
import bookingModule from './modules/booking';
import eventModule from './modules/event';
import feedbackModule from './modules/feedback';

export const store = new VueX.Store({
    modules:{
        auth: authModule,
        user: userModule,
        booking: bookingModule,
        event: eventModule,
        feedback: feedbackModule
    }
});