<template>
  <div class="booking" v-if="firstDate">
    <BookingSteps :currentStep="currentStep" :maxStep="4" v-if="currentStep"></BookingSteps>

    <form id="form-cta" name="form-cta" class="booking__cta" v-if="currentStep===0">
      <div class="booking__cta__bg"></div>
      <div class="booking__cta__partner" v-if="partner">
        <img src="../../assets/img/partners/tuilogo.png" v-if="partner==='TUI'" alt="TUI logo">
        <img src="../../assets/img/partners/deltourlogo.png" v-else-if="partner==='DEL-TOUR'" alt="DEL-TOUR logo">
        <img src="../../assets/img/partners/davidsfondslogo.png" v-else-if="partner==='DAVIDSFONDS'"
             alt="Davidsfonds logo">
      </div>
      <div class="booking__cta__title">{{ $t('booking.start') }}</div>
      <div class="booking__cta__login" v-if="!isAgent" v-html="$t('travelagents')">
      </div>
      <div class="booking__cta__login" v-if="loginWarningVisible">
        <p>
          <router-link to="/login">{{ $t('nav.login') }}</router-link>
          /
          <router-link to="/register">{{ $t('home.create') }}</router-link>
          {{ $t('home.createText') }}
        </p>
      </div>
      <div class="booking__form__field booking__form__field--cta">
        <div class="booking__form__field booking__form__field--cta half">
          <label class="checkbox checkbox--cta"
                 :class="{'disabled':!arrival}"><b>{{ $t('booking.heenreis') }}</b>
            <input type="checkbox" v-model="departure">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <div class="booking__form__field booking__form__field--cta half">
          <label class="checkbox checkbox--cta" :class="{'disabled':!departure}"><b>{{ $t('booking.terugreis') }}</b>
            <input type="checkbox" v-model="arrival">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </div>
      <div class="booking__form__field booking__form__field--cta">
        <div class="booking__form__field booking__form__field--cta half">
          <label for="postal">{{ $t('booking.postal') }}*</label>
          <input id="postal" name="postal" :placeholder="$t('booking.yourpostal')" @keyup="postalCTAChanged"
                 type="text"
                 :class="{'error':!departureCityValid}" v-model="pickupAddress.postal">
        </div>
        <div v-if="dCities" class="booking__form__field booking__form__field--cta half">
          <label for="postal">{{ $t('booking.city') }}</label>
          <p class="city">{{ dCities }} ({{ fromCountry }})</p>
        </div>
      </div>
      <div class="booking__form__field booking__form__field--cta">
        <label for="airports">{{ $t('booking.airport') }}*</label>
        <select class="select" :class="{'error':!airportDepartureValid}"
                id="airports"
                @change="onDepartureAirportChanged"
                v-model="airportDeparture">
          <option v-for="(airport) in airports" :value="airport.ID" :key="airport.ID">{{ airport.NAME }}
          </option>
        </select>
      </div>

      <div class="booking__form__field booking__form__field--cta">
        <label>{{ !departure && arrival ? $t('booking.datefrom') : $t('booking.dateto') }}*</label>
        <DatePicker v-if="!departure && arrival" :clearable="false" type="date" class="date" format="DD/MM/YYYY"
                    value-type="YYYY/MM/DD"
                    :start="pickupDate?pickupDate:firstDate" :disabled-date="eighthoursbeforeandpickup"
                    v-model="retourDate"></DatePicker>
        <DatePicker v-else type="date" class="date" :clearable="false" format="DD/MM/YYYY" value-type="YYYY/MM/DD"
                    :disabled-date="eighthoursbefore"
                    v-model="pickupDate"></DatePicker>
      </div>
    </form>
    <form id="form-booking" class="booking__form" v-else>
      <template v-if="currentStep===1">
        <h3>{{ $t('booking.persons') }}</h3>
        <div class="booking__form__field">
          <label>{{ $t('booking.amount') }}*</label>
          <input class="number" type="number" min="1" max="7" v-model="pax" @focusout="handleNumberInput($event,'pax')">
        </div>
        <h3>{{ $t('booking.addressinfo') }}</h3>
        <label class="checkbox" :class="{'disabled':!arrival}"><b><span
            v-html="$t('booking.heenreis')"></span></b>
          <input type="checkbox" v-model="departure"/>
          <span class="checkbox__checkmark"></span>
        </label>

        <template v-if="departure">
          <button v-if="account" class="button" @click.prevent="onShowPickupAddresses">
            {{ $t('account.chooseaddress') }}
          </button>
          <div class="booking__form__field">
            <label>{{ $t('booking.from') }}*</label>
            <input type="text" class="large" :class="{'error':!departureStreetValid}"
                   :placeholder="$t('booking.street')" v-model="pickupAddress.street"
                   @keyup="departureStreetValid=true"
                   @focusin="departureStreetsVisible = true">
            <input type="text" class="tiny" :class="{'error':!departureNrValid}"
                   @keyup="departureNrValid=true"
                   :placeholder="$t('booking.nr')" v-model="pickupAddress.nr">
            <div class="booking__form__streetcontainer"
                 v-if="pickupAddress.street && departureStreetsVisible">
              <div v-for="street in departureStreetArray" :key="street.ID"
                   @click="onSelectDepartureStreet(street)">{{ `${street.STRAAT} (${street.STAD})` }}
              </div>
            </div>
            <input type="text" class="small" :class="{'error':!departureCityValid}"
                   :placeholder="$t('booking.postal')" v-model="pickupAddress.postal"
                   @keyup="postalDepartureChanged">
            <span class="city" v-if="dCities">{{ dCities }} ({{ fromCountry }})</span>
          </div>

          <div class="booking__form__field">
            <div class="booking__form__field half">
              <label>{{ $t('booking.departuredate') }}*</label>
              <DatePicker type="date" class="date" format="DD/MM/YYYY" value-type="YYYY/MM/DD"
                          :class="{'error':!pickupDateValid}" :clearable="false" :disabled-date="eighthoursbefore"
                          v-model="pickupDate"></DatePicker>
            </div>
            <div class="booking__form__field half">
              <label>{{ $t('booking.airport') }}*</label>
              <select class="select" :class="{'error':!airportDepartureValid}"
                      id="airports-departure"
                      v-model="airportDeparture">
                <option v-for="(airport) in airports" :value="airport.ID" :key="airport.ID">{{ airport.NAME }}
                </option>
              </select>
            </div>

          </div>

          <div class="booking__form__field">
            <div class="booking__form__field half">
              <label>{{ $t('booking.flightnr') }}*</label>
              <input type="text" placeholder="AB1234" v-model="flightDeparture" class="flight"
                     @keyup="flightDepartureValid=true"
                     :class="{'error':!flightDepartureValid}">
            </div>
            <div class="booking__form__field half">
              <label>{{ $t('booking.flighthour') }}*</label>
              <DatePicker type="time" class="time" format="HH:mm" value-type="HH:mm"
                          :disabled-time="eighthoursbeforeflight" :clearable="false"
                          v-model="departureTime"></DatePicker>
            </div>
          </div>
          <div class="booking__form__field">
            <label>{{ $t('booking.margin') }}*</label>
            <select class="select select--small" v-model="timeMargin">
              <option value="2">2</option>
              <option value="2.5">2.5</option>
              <option value="3">3</option>
            </select>
          </div>
        </template>

        <label class="checkbox" :class="{'disabled':!departure}"><b><span
            v-html="$t('booking.terugreis')"></span></b>
          <input type="checkbox" v-model="arrival"/>
          <span class="checkbox__checkmark"></span>
        </label>
        <template v-if="arrival">
          <button class="button" v-if="departure" @click.prevent="sameAddressClicked">
            {{ $t('booking.sameaddress') }}
          </button>
          <button v-if="account" class="button" @click.prevent="onShowRetourAddresses">
            {{ $t('account.chooseaddress') }}
          </button>
          <div class="booking__form__field">
            <label>{{ $t('booking.retouraddress') }}*</label>

            <input type="text" class="large" :class="{'error':!retourStreetValid}"
                   @keyup="retourStreetValid=true"
                   :placeholder="$t('booking.street')" v-model="retourAddress.street"
                   @focusin="onRetourFocused">
            <input type="text" class="tiny" :class="{'error':!retourNrValid}"
                   @keyup="retourNrValid=true"
                   :placeholder="$t('booking.nr')" v-model="retourAddress.nr" @focusin="onRetourFocused">
            <div class="booking__form__streetcontainer" v-if="retourAddress.street && retourStreetsVisible">
              <div v-for="street in retourStreetArray" :key="street.ID"
                   @click="onSelectRetourStreet(street)">{{ `${street.STRAAT} (${street.STAD})` }}
              </div>
            </div>
            <input type="text" class="small" :class="{'error':!retourCityValid}"
                   :placeholder="$t('booking.postal')" v-model="retourAddress.postal"
                   @focusin="onRetourFocused" @keyup="postalRetourChanged">
            <span class='city' v-if="rCities">{{ rCities }} ({{ toCountry }})</span>
          </div>
          <div class="booking__form__field">
            <div class="booking__form__field half">
              <label>{{ $t('booking.landingdate') }}*</label>
              <DatePicker type="date" class="date" :class="{'error':!retourDateValid}" format="DD/MM/YYYY"
                          value-type="YYYY/MM/DD" :clearable="false"
                          :disabled-date="eighthoursbeforeandpickup"
                          v-model="retourDate"></DatePicker>
            </div>
            <div class="booking__form__field half">
              <label>{{ $t('booking.airportlanding') }}*</label>
              <select class="select" id="airportsretour" v-model="airportRetour">
                <option v-for="(airport) in airports" :value="airport.ID" :key="airport.ID">
                  {{ airport.NAME }}
                </option>
              </select>
            </div>
          </div>
          <div class="booking__form__field">
            <div class="booking__form__field half">
              <label>{{ $t('booking.flightnr') }}*</label>
              <input type="text" placeholder="AB1234" v-model="flightRetour" class="flight"
                     @keyup="flightRetourValid=true"
                     :class="{'error':!flightRetourValid}">
            </div>
            <div class="booking__form__field half">
              <label>{{ $t('booking.landinghour') }}*</label>
              <DatePicker type="time" class="time" format="HH:mm" :clearable="false" value-type="HH:mm"
                          v-model="retourTime"
                          :disabled-time="eighthoursbeforeflightretour"></DatePicker>
            </div>

          </div>
          <div class="booking__form__field">
            <label>{{ $t('booking.origin') }}*</label>
            <input type="text" @keyup="airportAbroadRetourValid=true"
                   :class="{'error':!airportAbroadRetourValid}" v-model="airportAbroadRetour">
          </div>
        </template>
      </template>
      <template v-else-if="currentStep===2">
        <h3>{{ $t('booking.transportationtype') }}</h3>
        <div class="booking__form__field">
          <p class="error" v-if="!combiEnabled && type !=='COMBINATIE' && new Date(arrival && !departure?retourDate : pickupDate) >= combiDeath"><b>{{ $t('booking.combideath') }}</b></p>
          <p class="error" v-else-if="!combiEnabled && type !=='COMBINATIE'"><b>{{ $t('booking.combidisabled') }}</b></p>
          <select class="select" v-model="type">
            <option v-for="type in types" :key="type.label" :value="type.value"
                    :disabled="type.value==='COMBINATIE'&&!combiEnabled">
              {{ $t('booking.' + type.value.toLowerCase()) }}
            </option>
          </select>

        </div>
        <p v-html="$t('vervoer.'+type.toLowerCase())"></p>
        <h3>{{ $t('booking.extras') }}</h3>
        <div class="booking__form__field">
          <div id="progress">
            <div id="progress-inner" :class="percentageClass"></div>
          </div>
        </div>
        <div class="booking__form__field">
          <label>{{ $t('booking.persons') }}*</label>
          <input class="number" type="number" min="1" max="7" v-model="pax" @focusout="handleNumberInput($event,'pax')">
        </div>
        <div class="booking__form__field">
          <label>{{ $t('booking.handluggage') }}</label>
          <p>{{ $t('booking.handluggagetext') }}</p>
        </div>
        <div class="booking__form__field">
          <div class="booking__form__field half">
            <label>{{ $t('booking.suitcases') }} <span class="sup">({{ $t('booking.handexcl') }})</span></label>
            <input type="number" class="number" min="0" max="8" v-model="koffers" :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'koffers')">
            <label>{{ $t('booking.bike') }}</label>
            <input type="number" class="number" min="0" max="3" v-model="fietskoffers"
                   :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'fietskoffers')">
            <label>{{ $t('booking.wheelchairs') }} <span class="sup">{{ $t('booking.folding') }}</span></label>
            <input type="number" class="number" min="0" max="3" v-model="rolstoelen" :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'rolstoelen')">
            <label>{{ $t('booking.rollator') }}</label>
            <input type="number" class="number" min="0" max="3" v-model="rollators" :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'rollators')">
          </div>
          <div class="booking__form__field half">
            <label>{{ $t('booking.kinderwagens') }}</label>
            <input type="number" class="number" min="0" max="8" v-model="kinderwagens"
                   :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'kinderwagens')">
            <label>{{ $t('booking.skis') }}</label>
            <input type="number" class="number" min="0" max="8" v-model="skis" :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'skis')">
            <label>{{ $t('booking.golfbags') }}</label>
            <input type="number" class="number" min="0" max="8" v-model="golfbags" :class="{'error':cargo>MAX_CARGO}"
                   @focusout="handleNumberInput($event,'golfbags')">
          </div>
        </div>
        <div class="booking__form__field">
          <p><b>{{ $t('booking.kinderstoelen') }}</b> <span class="sup">(max 2)</span></p>
          <div class="booking__form__field third">
            <label>{{ $t('booking.kinderstoelen1') }}</label>
            <input type="number" class="number" min="0" max="2" v-model="kinderstoelenSmall"
                   :class="{'error':kinderstoelen>2}"
                   @focusout="handleNumberInput($event,'kinderstoelenSmall')">
          </div>
          <div class="booking__form__field third">
            <label>{{ $t('booking.kinderstoelen2') }}</label>
            <input type="number" class="number" min="0" max="2" v-model="kinderstoelenMedium"
                   :class="{'error':kinderstoelen>2}"
                   @focusout="handleNumberInput($event,'kinderstoelenMedium')">
          </div>
          <div class="booking__form__field third">
            <label>{{ $t('booking.kinderstoelen3') }}</label>
            <input type="number" class="number" min="0" max="2" v-model="kinderstoelenLarge"
                   :class="{'error':kinderstoelen>2}"
                   @focusout="handleNumberInput($event,'kinderstoelenLarge')">
          </div>
        </div>
      </template>
      <template v-else-if="currentStep===3">
        <h3>{{ $t('booking.personalia') }}</h3>
        <div class="booking__form__field">
          <label>{{ $t('booking.firstname') }}*</label>
          <input type="text" v-model="firstName"
                 @keyup="firstNameValid=true"
                 :class="{'error':!firstNameValid}">
          <label>{{ $t('booking.name') }}*</label>
          <input type="text" v-model="lastName"
                 @keyup="lastNameValid=true"
                 :class="{'error':!lastNameValid}">
          <label>{{ $t('booking.email') }}*</label>
          <input type="email" v-model="email"
                 @keyup="mailValid=true"
                 :class="{'error':!mailValid}">
          <label>{{ $t('booking.phone') }}*</label>
          <vue-tel-input :preferredCountries="['BE','NL','FR','LU']" v-model="phone"
                         @input="phoneInputValid=true"
                         :class="{'error':!phoneInputValid}" @validate="handlePhoneValidate"></vue-tel-input>
        </div>
        <div class="booking__form__field">
          <label class="checkbox checkbox--small" :class="{'checkbox--error':!gdprValid}">{{ $t('booking.gdpr1') }}
            <a :href="privacy" target="_blank">{{ $t('booking.gdpr2') }}</a>
            <input type="checkbox" v-model="gdprCompliant">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <div class="booking__form__field">
          <label class="checkbox checkbox--small" :class="{'checkbox--error':!termsValid}">{{ $t('booking.terms1') }}
            <router-link target="_blank" to="/terms">{{ $t('booking.terms2') }}</router-link>
            <input type="checkbox" v-model="termsCompliant">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
        <div class="booking__form__field">
          <label class="checkbox checkbox--small" :class="{'checkbox--error':!consentValid}">{{ $t('booking.consent') }}
            <input type="checkbox" v-model="consentCompliant">
            <span class="checkbox__checkmark"></span>
          </label>
        </div>
      </template>
    </form>
    <div class="booking__overview" v-if="currentStep">
      <div class="booking__overview__partner" v-if="partner">
        <img src="../../assets/img/partners/tuilogo.png" v-if="partner==='TUI'" alt="TUI logo">
        <img src="../../assets/img/partners/deltourlogo.png" v-if="partner==='DEL-TOUR'" alt="DEL-TOUR logo">
        <img src="../../assets/img/partners/davidsfondslogo.png" v-if="partner==='DAVIDSFONDS'" alt="Davidsfonds logo">
      </div>
      <h3>{{ $t('booking.overview') }}</h3>
      <p>{{ $t('booking.personamount') }}: <b>{{ pax }}</b></p>
      <p>{{ $t('booking.transportationtype') }}: <b>{{ $t('booking.' + type.toLowerCase()) }}</b></p>
      <p>{{ $t('booking.suitcases') }}: <b>{{ koffers }}</b></p>
      <p v-if="skis">{{ $t('booking.skis') }}: <b>{{ skis }}</b></p>
      <p v-if="golfbags">{{ $t('booking.golfbags') }}: <b>{{ golfbags }}</b></p>
      <p v-if="fietskoffers">{{ $t('booking.bike') }}: <b>{{ fietskoffers }}</b></p>
      <p v-if="rolstoelen">{{ $t('booking.wheelchairs') }}: <b>{{ rolstoelen }}</b></p>
      <p v-if="rollators">{{ $t('booking.rollator') }}: <b>{{ rollators }}</b></p>
      <p v-if="kinderwagens">{{ $t('booking.kinderwagens') }}: <b>{{ kinderwagens }}</b></p>
      <p v-if="kinderstoelen">{{ $t('booking.kinderstoelenamount') }}: <b>{{ kinderstoelen }}</b></p>
      <div class="booking__overview__talic">
        <template v-if="price && !isAgent && price.KORTINGPODATA && price.KORTINGPODATA.BETAALDDOORTRAVELAGENT">
          <h3>Agent</h3>
          <p v-if="quid">{{ quid }}</p>
          <p>{{ price.KORTINGPODATA.NAAMAGENT }} </p>
          <p>{{ price.KORTINGPODATA.EMAILAGENT }}</p>
          <p>{{ price.KORTINGPODATA.CAMPAGNE }}</p>
        </template>
        <template v-else-if="qtalic">
          <h3>Agent</h3>
          <p>{{ quid }} </p>
          <p>{{ qtaName }} ({{ qtalic }})</p>
          <p>{{ qtaEmail }}</p>
        </template>
      </div>
      <div class="booking__overview__column" v-if="departure">
        <h3>{{ $t('booking.heen') }}</h3>
        <div class="booking__overview__block">
          <div class="booking__overview__address">
            <p>{{ pickupAddress.street }} {{ pickupAddress.nr }}</p>
            <p>{{ pickupAddress.postal }} {{ departureCity }}</p>
          </div>
          <div class="booking__overview__pointer booking__overview__pointer--departure"></div>
          <div class="booking__overview__address">
            <p>{{ airportDataHeen.NAME }}</p>
          </div>
          <p>{{ formatDateSql(pickupDate) }}</p>
          <p>{{ departureTime }}</p>
        </div>
      </div>
      <div class="booking__overview__column" v-if="arrival">
        <h3>{{ $t('booking.terug') }}</h3>
        <div class="booking__overview__block">
          <div class="booking__overview__address">
            <p>{{ airportDataTerug.NAME }}</p>
          </div>
          <div class="booking__overview__pointer booking__overview__pointer--arrival"></div>
          <div class="booking__overview__address">
            <p>{{ retourAddress.street }} {{ retourAddress.nr }}</p>
            <p>{{ retourAddress.postal }} {{ retourCity }}</p>
          </div>
          <p>{{ formatDateSql(retourDate) }}</p>
          <p>{{ retourTime }}</p>
        </div>
      </div>
      <div class="booking__overview__column">
        <h3>{{ $t('booking.price') }}</h3>
        <div class="booking__overview__price" v-if="price && !priceLoading">
          <div class="booking__overview__price__column" v-if="price.BRUTOPRIJSHEEN">
            <p><b>{{ $t('booking.heen') }}</b></p>
            <p class="price" v-if="price.BRUTOPRIJSHEEN !== priceDeparture">{{ $t('booking.bruto') }}:
              <b>€{{ price.BRUTOPRIJSHEEN }}</b>
            </p>
            <p class="price price--redux" v-if="price.KORTINGHEEN">{{ $t('booking.reduction') }}: <b>-
              €{{ price.KORTINGHEEN }}</b></p>
            <p class="price price--extra"
               v-if="price.KORTINGHEEN">
              {{ $t('booking.meerprijs') }}: <b>+
              €{{ parseFloat(price.PRIJSKLANTHEEN) + parseFloat(price.KORTINGCOUPONSHEEN) }}</b></p>
            <p class="price price--redux" v-if="price.KORTINGCOUPONSHEEN">{{ $t('booking.coupon') }}: <b>-
              €{{ price.KORTINGCOUPONSHEEN }}</b></p>
            <p class="price" :class="{'price--sum':price.KORTINGHEEN}">{{ $t('booking.topay') }}:
              <b>€{{ priceDeparture }}</b>
            </p>
          </div>
          <div class="booking__overview__price__column" v-if="price.BRUTOPRIJSTERUG">
            <p><b>{{ $t('booking.terug') }}</b></p>
            <p class="price" v-if="price.BRUTOPRIJSTERUG !== priceRetour">{{ $t('booking.bruto') }}:
              <b>€{{ price.BRUTOPRIJSTERUG }}</b>
            </p>
            <p class="price price--redux" v-if="price.KORTINGTERUG">{{ $t('booking.reduction') }}: <b>-
              €{{ price.KORTINGTERUG }}</b></p>
            <p class="price price--extra"
               v-if="price.KORTINGTERUG">
              {{ $t('booking.meerprijs') }}: <b>+
              €{{ parseFloat(price.PRIJSKLANTTERUG) + parseFloat(price.KORTINGCOUPONSTERUG) }}</b></p>
            <p class="price price--redux" v-if="price.KORTINGCOUPONSTERUG">{{ $t('booking.coupon') }}: <b>-
              €{{ price.KORTINGCOUPONSTERUG }}</b></p>
            <p class="price" :class="{'price--sum':price.KORTINGTERUG}">{{ $t('booking.topay') }}:
              <b>€{{ priceRetour }}</b>
            </p>
          </div>
          <div class="price price--total">{{ $t('booking.totalprice') }}: <span>€{{ priceTotal }}</span></div>
        </div>
        <div class="booking__overview__loading" v-else>
          <img src="../../assets/img/loading.svg" alt="calculating your price"/>
        </div>
        <div class="booking__form__field">
          <label>{{ $t('booking.po') }}</label>
          <input type="text" v-model="POnr">
        </div>
        <div class="booking__form__field" v-if="price">
          <label>{{ $t('booking.coupon') }}</label>
          <p class="booking__overview__coupon" v-for="(item,index) in coupons" :key="`coupon--${item}`">
            <template v-if="price.COUPONDATA && price.COUPONDATA[index]">{{ item }} -
              {{ price.COUPONDATA[index].CAMPAGNE }} - €{{ price.COUPONDATA[index].COUPONKORTING }}
              <button class="button button--coupon" @click="removeCoupon(index)">
                <fa-icon icon="times"></fa-icon>
              </button>
            </template>
          </p>
          <button class="button" :class="{'button--disabled':priceTotal<=0}"
                  @click="isCouponPopupVisible=true">{{ $t('booking.addcoupon') }}
          </button>
        </div>
        <router-link to="/pricing" target="_blank">{{ $t('pricing.link') }}</router-link>
      </div>
    </div>

    <div class="booking__errors">
      <template v-if="currentStep === 0">
        <p class="error" v-if="!departureCityValid">{{ $t('errors.departureCity') }}</p>
      </template>
      <template v-if="currentStep === 1">
        <template v-if="departure">
          <p class="error" v-if="!departureCityValid || !departureNrValid || !departureStreetValid">
            {{ $t('errors.departureAddress') }}</p>
          <p class="error" v-if="!flightDepartureValid">{{ $t('errors.departureFlight') }}</p>
        </template>
        <template v-if="arrival">
          <p class="error" v-if="!retourCityValid || !retourNrValid || !retourStreetValid">{{
              $t('errors.retourAddress')
            }}</p>
          <p class="error" v-if="!flightRetourValid">{{ $t('errors.retourFlight') }}</p>
          <p class="error" v-if="!airportAbroadRetourValid">{{ $t('errors.origin') }}</p>
        </template>
      </template>
      <template v-if="currentStep === 2">
        <p class="error" v-if="cargo>16">{{ $t('errors.cargo') }}</p>
        <p class="error" v-if="kinderstoelen>2">{{ $t('errors.chairs') }}</p>
      </template>
      <template v-if="currentStep === 3">
        <p class="error" v-if="!firstNameValid">{{ $t('errors.firstname') }}</p>
        <p class="error" v-if="!lastNameValid">{{ $t('errors.lastname') }}</p>
        <p class="error" v-if="!mailValid">{{ $t('errors.email') }}</p>
        <p class="error" v-if="!phoneInputValid">{{ $t('errors.phone') }}</p>
        <p class="error" v-if="!gdprCompliant && !gdprValid">{{ $t('errors.gdpr') }}</p>
        <p class="error" v-if="!termsCompliant && !termsValid">{{ $t('errors.terms') }}</p>
        <p class="error" v-if="!consentCompliant && !consentValid">{{ $t('errors.consent') }}</p>
      </template>
    </div>
    <div class="booking__controls">
      <button v-if="currentStep" class="booking__controls__btn booking__controls__btn--prev"
              :class="{'disabled':paymentLoading}" @click="onPreviousClicked">{{ $t('previous') }}
      </button>
      <button v-if="currentStep<3" class="booking__controls__btn booking__controls__btn--next"
              @click="onNextClicked">{{ $t('next') }}
      </button>
      <button v-else-if="currentStep" class="booking__controls__btn booking__controls__btn--next"
              :class="{'disabled':paymentLoading}" @click="onConfirmClicked">{{ $t('booking.save') }}
      </button>
    </div>

    <div class="popup" v-if="bookingWarning">
      <div class="popup__wrapper">
        <p class="popup__title">{{ $t('booking.warningtitle') }}</p>
        <p class="popup__text" v-if="bookingWarning.koffers">{{ $t('booking.warningsuitcasestext') }}</p>
        <p class="popup__text" v-if="bookingWarning.dates">{{ $t('booking.warningdatestext') }}</p>
        <p class="popup__text">{{ $t('booking.warningtext') }}</p>
        <div class="popup__controls">
          <button class="button button--accept" @click="onBookingConfirmed">{{ $t('yes') }}</button>
          <button class="button button--decline" @click="onBookingDeclined">{{ $t('no') }}</button>
        </div>
      </div>
    </div>
    <div class="popup" v-if="isPickupPopupVisible">
      <div class="popup__wrapper">
        <button class="popup__close" @click="isPickupPopupVisible=false">
          <fa-icon icon="times"></fa-icon>
        </button>
        <p class="popup__title">{{ $t('account.choosepickup') }}</p>
        <div class="popup__body">
          <ul class="list">
            <li v-for="item in accountAddresses" :key="'pickupaddress--'+item.id"
                @click="onSelectAccountPickup(item)">
              {{ `${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="popup" v-if="isRetourPopupVisible">
      <div class="popup__wrapper">
        <button class="popup__close" @click="isRetourPopupVisible=false">
          <fa-icon icon="times"></fa-icon>
        </button>
        <p class="popup__title">{{ $t('account.chooseretour') }}</p>
        <div class="popup__body">
          <ul class="list">
            <li v-for="item in accountAddresses" :key="'retouraddress--'+item.id"
                @click="onSelectAccountRetour(item)">
              {{ `${item.street} ${item.streetnr} - ${item.zip} ${item.city} (${item.country})` }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <BookingCoupon v-if="isCouponPopupVisible" :coupons="coupons"
                   @modal-close="isCouponPopupVisible=false"></BookingCoupon>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import {VueTelInput} from 'vue-tel-input';
import BookingSteps from "./BookingSteps";
import BookingCoupon from "./BookingCoupon";
import PaymentService from "../../services/payment";
import {ObjectMixin} from "../../mixins/ObjectMixin";

export default {
  name: "Booking",

  props: {
    qtalic: {},
    qtaEmail: {},
    qtaName: {},
    quid: {},
    partner: {},
    isAgent: {}
  },

  components: {
    BookingSteps,
    BookingCoupon,
    DatePicker,
    VueTelInput
  },

  mixins: [ObjectMixin],

  data() {
    return {
      today: null,
      timeLimit: null,
      startDate: null,

      privacy: process.env.VUE_APP_PRIVACY,

      MAX_CARGO: process.env.VUE_APP_BOOKING_MAXCARGO,
      currentStep: 0,

      couponValid: true,
      gdprCompliant: false,
      termsCompliant: false,
      consentCompliant: false,
      priceLoading: false,

      retourFocused: false,

      departureStreetArray: null,
      retourStreetArray: null,

      retourStreetSelected: false,
      retourPostalSelected: false,
      retourNrSelected: false,

      departure: true,
      arrival: true,

      departureCity: null,
      retourCity: null,

      pickupAddress: {street: "", postal: "", nr: ""},
      retourAddress: {street: "", postal: "", nr: ""},

      airportDeparture: null,
      flightDeparture: "",

      airportRetour: null,
      flightRetour: "",

      airportAbroadRetour: null,

      firstDate: null,
      pickupDate: null,
      retourDate: null,
      type: "COMBINATIE",

      talic: this.qtalic || "",
      uid: this.quid || "",
      naamagent: this.qtaName || "",
      emailagent: this.qtaEmail || "",
      POnr: "",
      coupons: [],
      timeMargin: "2",
      departureTime: "00:00",
      retourTime: "00:00",

      pax: 2,
      koffers: 2,
      skis: 0,
      golfbags: 0,
      rolstoelen: 0,
      rollators: 0,
      fietskoffers: 0,
      kinderstoelenSmall: 0,
      kinderstoelenMedium: 0,
      kinderstoelenLarge: 0,
      kinderwagens: 0,

      fromCountry: "",
      toCountry: "",
      distanceDeparture: "0",
      distanceRetour: "0",
      latlonDepartureVan: "0,0",
      latlonDepartureNaar: "0,0",
      latlonRetourVan: "0,0",
      latlonRetourNaar: "0,0",
      reistijdheen: "",
      reistijdterug: "",

      rCities: "",
      dCities: "",

      firstName: "",
      lastName: "",
      email: "",
      phone: "",

      paymentMode: 'CREDITCARD',
      paymentLoading: false,

      bookingWarning: null,

      phoneValid: false,
      airportDepartureValid: true,
      departureCityValid: true,
      pickupDateValid: true,
      flightDepartureValid: true,
      departureStreetValid: true,
      departureNrValid: true,
      airportRetourValid: true,
      airportAbroadRetourValid: true,
      retourCityValid: true,
      retourDateValid: true,
      flightRetourValid: true,
      retourStreetValid: true,
      retourNrValid: true,
      firstNameValid: true,
      lastNameValid: true,
      phoneInputValid: true,
      mailValid: true,
      gdprValid: true,
      termsValid: true,
      consentValid: true,

      formValid: true,

      phoneprefs: ['be', 'nl', 'fr', 'de', 'lu'],

      types: [{label: "Combi", value: "COMBINATIE"}, {label: "Comfort", value: "COMFORT"}, {
        label: "Business",
        value: "BUSINESS"
      }],

      combiEnabled: true,
      extras: [],
      couponData: null,

      pickupTimeout: null,
      retourTimeout: null,
      priceTimeout: null,

      departureStreetsVisible: false,
      retourStreetsVisible: false,


      isPickupPopupVisible: false,
      isRetourPopupVisible: false,
      isCouponPopupVisible: false
    };
  },

  created() {
    this.resetValid();
    this.checkAccountPresets();

    this.fetchAirports();


    let td = new Date();
    //td.setSeconds(0, 0);
    this.today = td;
    //this.limit = new Date(this.today.getTime() + 8 * 3600 * 1000);
    this.limit = new Date();
    this.limit.setDate(this.limit.getDate() + 3);

    if(this.limit>=this.combiDeath){
      this.type = "COMFORT";
    }

    this.startDate = new Date();
    //this.startDate.setDate(this.startDate.getDate() + 2);
    this.startDate.setDate(this.startDate.getDate() + 3);
    this.startDate.setHours(0, 0, 0, 0);
    const mm = this.startDate.getMonth() + 1; // getMonth() is zero-based
    const dd = this.startDate.getDate();
    const dateFormatted = this.startDate.getFullYear() + '/' + (mm > 9 ? '' : '0') + mm + '/' + (dd > 9 ? '' : '0') + dd;
    this.firstDate = dateFormatted;
    this.pickupDate = dateFormatted;
    this.retourDate = dateFormatted;
  },

  beforeDestroy() {
    clearTimeout(this.pickupTimeout);
    clearTimeout(this.retourTimeout);
    clearTimeout(this.priceTimeout);
  },

  computed: {

    combiDeath(){
      //return this.price
      //&& this.price.PO
      //&& this.price.KORTINGPODATA
      //&& this.price.KORTINGPODATA.BETAALDDOORTRAVELAGENT
      //&& (this.price.KORTINGPODATA.CAMPAGNE === "TUIACTIE10" || this.price.KORTINGPODATA.CAMPAGNE === "TUIACTIE9")
      //    ? new Date('2022/04/01') : new Date('2021/09/16');
      return new Date('2021/09/16');
    },

    loginWarningVisible(){
      return !this.account && !this.qtalic;
    },
    airports() {
      return this.$store.getters['booking/airports'];
    },

    account() {
      return this.$store.getters['user/userData'];
    },

    accountAddresses() {
      return this.account ? this.account.addresses.filter(a => a.billoaddress) : null;
    },

    price() {
      return this.$store.getters['booking/price'];
    },

    airportDataHeen() {
      return this.airportDeparture ? this.airports.find(a => a.ID === this.airportDeparture) : null;
    },
    airportDataTerug() {
      return this.airportRetour ? this.airports.find(a => a.ID === this.airportRetour) : null;
    },

    kinderstoelen() {
      return parseInt(this.kinderstoelenSmall) + parseInt(this.kinderstoelenMedium) + parseInt(this.kinderstoelenLarge);
    },

    cargo() {
      return parseInt(this.pax) + parseInt(this.koffers) + parseInt(this.rolstoelen) * 5 + parseInt(this.skis) * 2 + parseInt(this.golfbags) * 2 + parseInt(this.rollators) * 5 + parseInt(this.fietskoffers) * 5 + parseInt(this.kinderwagens) * 2;
    },

    cargoValid() {
      const amountValid = this.cargo <= this.MAX_CARGO;
      return amountValid && (this.kinderstoelen <= 2);
    },

    fromheen() {
      const address = this.pickupAddress;
      const city = this.departureCity;
      return address.postal && city && address.postal !== "" && city !== "" ? `${address.postal} ${city} ${this.fromCountry}` : null;
    },

    toheen() {
      const address = this.retourAddress;
      const city = this.retourCity;
      return address.postal && city && address.postal !== "" && city !== "" ? `${address.postal} ${city} ${this.toCountry}` : null;
    },

    heenterug() {
      if (this.departure && this.arrival) {
        return "HT";
      } else if (this.departure) {
        return "H";
      } else {
        return "T";
      }
    },

    priceDeparture() {
      const prijs = this.price;
      const pHeen = parseFloat(prijs.PRIJSKLANTHEEN);
      return pHeen <= 0 ? 0 : pHeen;
    },

    priceRetour() {
      const prijs = this.price;
      const pTerug = parseFloat(prijs.PRIJSKLANTTERUG);
      return pTerug <= 0 ? 0 : pTerug;
    },

    priceTotal() {
      return parseFloat(this.priceDeparture) + parseFloat(this.priceRetour);
    },

    percentageClass() {
      return this.cargo / this.MAX_CARGO > 1 ? 'full' : '';
    }
  },

  watch: {
    account(){
      if(this.account){
        this.checkAccountPresets();
      }
    },
    pickupAddress: {
      handler() {
        clearTimeout(this.pickupTimeout);
        this.pickupTimeout = setTimeout(async () => {
          const postal = this.pickupAddress.postal;
          const nr = this.pickupAddress.nr;
          let street = this.pickupAddress.street;

          if (!this.retourFocused) {
            this.retourAddress.nr = nr;
            this.retourAddress.street = street;
            this.retourAddress.postal = postal;
          }

          street = street ? street : "";
          if (postal) {
            try {
              const res = await this.$store.dispatch('booking/getStreets', {postal, street});
              this.departureStreetArray = res.data;
              this.dCities = res.metadata.citynames;
              if (!this.departureCity) {
                this.departureCity = res.data[0].STAD;
              }
              this.fromCountry = res.data[0].LAND;
              if (!this.retourAddress.postal) {
                this.retourAddress.postal = postal;
              }
            } catch (e) {
              this.departureStreetArray = null;
              this.departureCity = null;
              this.fromCountry = null;
              this.dCities = "";
            }
          } else {
            this.departureStreetArray = null;
            this.departureCity = null;
            this.fromCountry = null;
            this.dCities = "";
          }
        }, 500);
      },
      deep: true
    },

    retourAddress: {
      handler() {
        clearTimeout(this.retourTimeout);
        this.retourTimeout = setTimeout(async () => {
          const postal = this.retourAddress.postal;
          let street = this.retourAddress.street;

          street = street ? street : "";
          if (postal) {
            try {
              const res = await this.$store.dispatch('booking/getStreets', {postal, street});
              this.rCities = res.metadata.citynames;
              this.retourStreetArray = res.data;
              this.toCountry = res.data[0].LAND;
              if (!this.retourCity) {
                this.retourCity = res.data[0].STAD;
              }
            } catch (e) {
              this.retourStreetArray = null;
              this.retourCity = null;
              this.toCountry = null;
              this.rCities = "";
            }
          } else {
            this.retourStreetArray = null;
            this.retourStreetArray = null;
            this.retourCity = null;
            this.toCountry = null;
            this.rCities = "";
          }
        }, 500);
      },
      deep: true
    },

    'coupons.length': {
      handler() {
        this.priceInputChanged();
      }
    },

    cargo() {
      this.setCargoProgress();
    },

    heenterug() {
      this.locationsChanged()
    },

    departure() {
      this.checkCombiEnabled();
    },

    arrival() {
      this.checkCombiEnabled();
    },

    fromheen() {
      this.locationsChanged()
    },
    toheen() {
      this.locationsChanged()
    },
    airportDeparture() {
      this.locationsChanged()
    },
    airportRetour() {
      this.locationsChanged()
    },

    pickupDate() {
      const limit = this.limit;
      const pDate = new Date(`${this.pickupDate} ${this.departureTime}`);
      const rDate = new Date(`${this.retourDate} ${this.retourTime}`);
      const sd = this.startDate;
      if (pDate > rDate) {
        this.retourDate = this.pickupDate;
      }
      if (pDate < limit) {
        const h = limit.getHours();
        const m = limit.getMinutes();
        this.departureTime = `${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}`;
      }
      this.combiEnabled = true;
      pDate.setHours(0, 0, 0, 0);
      if (pDate < sd || pDate >= this.combiDeath) {
        this.combiEnabled = false;
      }
      this.priceInputChanged()
    },

    retourDate() {
      const limit = this.limit;
      const pDate = new Date(this.pickupDate);
      const rDate = new Date(this.retourDate);
      if (pDate > rDate) {
        this.pickupDate = this.retourDate;
      }
      if (rDate < limit) {
        const h = limit.getHours();
        const m = limit.getMinutes();
        this.retourTime = `${h > 9 ? h : `0${h}`}:${m > 9 ? m : `0${m}`}`;
      }
      rDate.setHours(0, 0, 0, 0);
      const sd = this.startDate;
      this.combiEnabled = true;
      if (rDate < sd || rDate >= this.combiDeath) {
        this.combiEnabled = false;
      }
      this.priceInputChanged()
    },

    combiDeath(){
      this.checkCombiEnabled();
      if(this.price && this.price.PO && this.price.KORTINGPODATA && (this.price.KORTINGPODATA.CAMPAGNE === "TUIACTIE10" || this.price.KORTINGPODATA.CAMPAGNE === "TUIACTIE9")){
        if(this.combiEnabled){
          this.type = "COMBINATIE";
        }
        else{
          this.type = "COMFORT";
        }
      }
    },

    combiEnabled() {
      if (!this.combiEnabled && this.type === 'COMBINATIE') {
        this.type = 'COMFORT';
      }
    },

    type() {
      this.priceInputChanged()
    },

    pax() {
      this.priceInputChanged()
    },
    talic() {
      this.priceInputChanged()
    },
    koffers() {
      this.priceInputChanged()
    },
    skis() {
      this.priceInputChanged()
    },
    golfbags() {
      this.priceInputChanged()
    },
    rolstoelen() {
      this.priceInputChanged()
    },
    rollators() {
      this.priceInputChanged()
    },
    fietskoffers() {
      this.priceInputChanged()
    },
    kinderstoelen() {
      this.priceInputChanged()
    },
    kinderwagens() {
      this.priceInputChanged()
    },

    POnr() {
      this.priceInputChanged();
    },

    'pickupAddress.street': {
      handler() {
        if (this.departureStreetArray && this.departureStreetArray.some(s => s['STRAAT'].toLowerCase() === this.pickupAddress.street.toLowerCase())) {
          this.locationsChanged();
        }
      }
    },

    'retourAddress.street': {
      handler() {
        if (this.retourStreetArray && this.retourStreetArray.some(s => s['STRAAT'].toLowerCase() === this.retourAddress.street.toLowerCase())) {
          this.locationsChanged();
        }
      }
    },

    currentStep() {
      this.$nextTick(() => {
        this.scrollIntoView();
      }, 0);
    }
  },

  methods: {

    async fetchAirports() {
      const res = await this.$store.dispatch('booking/getAirports');
      if (res) {
        this.airportDeparture = res[0].ID;
        this.airportRetour = res[0].ID;
      }
    },

    scrollIntoView() {
      const form = document.getElementById('form-booking');
      if (form) {
        form.scrollIntoView();
      }
    },

    checkAccountPresets() {
      if (!this.account) {
        return;
      }
      const address = this.account.addresses.find(a => a.defaultaddress);
      if (address) {
        this.pickupAddress.street = address.street;
        this.retourAddress.street = address.street;
        this.pickupAddress.postal = address.zip;
        this.retourAddress.postal = address.zip;
        this.pickupAddress.nr = address.streetnr;
        this.retourAddress.nr = address.streetnr;
      }
      this.phone = this.account.user.phone;
      this.email = this.account.user.email;
      this.firstName = this.account.user.firstname;
      this.lastName = this.account.user.lastname;
    },

    priceInputChanged() {
      clearTimeout(this.priceTimeout);
      this.priceTimeout = setTimeout(() => {
        this.checkPrice();
      }, 1000);
    },

    locationsChanged() {
      this.distanceDeparture = "0";
      this.distanceRetour = "0";
      this.latlonDepartureVan = "0,0";
      this.latlonDepartureNaar = "0,0";
      this.latlonRetourVan = "0,0";
      this.latlonRetourNaar = "0,0";
      this.reistijdheen = "";
      this.reistijdterug = "";
      clearTimeout(this.priceTimeout);
      this.priceTimeout = setTimeout(() => {
        this.checkPrice();
      }, 1000);
    },

    async checkPrice() {
      let datum = this.pickupDate ? this.pickupDate : this.retourDate;
      let fromheen = this.departureStreetArray && this.departureStreetArray.some(s => s['STRAAT'].toLowerCase() === this.pickupAddress.street.toLowerCase()) ? this.pickupAddress.street + " " + this.fromheen : this.fromheen;
      let toretour = this.retourStreetArray && this.retourStreetArray.some(s => s['STRAAT'].toLowerCase() === this.retourAddress.street.toLowerCase()) ? this.retourAddress.street + " " + this.toheen : this.toheen;
      const queryData = {
        po: this.POnr,
        talic: this.talic,
        coupons: this.coupons,
        soortvervoer: this.type,
        datum: datum || "",
        pax: this.pax,
        koffers: this.koffers,
        kinderstoelen: this.kinderstoelen,
        kinderwagens: this.kinderwagens,
        skis: this.skis,
        golfbags: this.golfbags,
        rolstoelen: this.rolstoelen,
        rollators: this.rollators,
        fietskoffers: this.fietskoffers,
        heenterug: this.heenterug,
        idluchthavenheen: this.airportDeparture ? this.airportDeparture : "",
        fromheen: fromheen,
        fromlandheen: this.fromCountry,
        toheen: this.airportDeparture ? this.airports.find(a => a.ID === this.airportDeparture).ADRES : "",
        idluchthavenretour: this.airportRetour ? this.airportRetour : "",
        fromretour: this.airportRetour ? this.airports.find(a => a.ID === this.airportRetour).ADRES : "",
        toretour: toretour,
        tolandretour: this.toCountry,
        afstandheen: this.distanceDeparture,
        afstandretour: this.distanceRetour,
        heenlatlonnaar: this.latlonDepartureNaar,
        heenlatlonvan: this.latlonDepartureVan,
        teruglatlonnaar: this.latlonRetourNaar,
        teruglatlonvan: this.latlonRetourVan,
        reistijdheen: this.reistijdheen,
        reistijdterug: this.reistijdterug
      };

      if ((queryData.idluchthavenheen || queryData.idluchthavenretour) && ((queryData.fromheen && queryData.toheen) || queryData.fromretour && queryData.toretour) && (queryData.datum !== "")) {
        this.priceLoading = true;
        try {
          const res = await this.$store.dispatch('booking/postPriceParams', queryData);
          this.distanceDeparture = res.AFSTANDHEEN;
          this.distanceRetour = res.AFSTANDRETOUR;
          this.latlonDepartureVan = res.HEENLATLONVAN;
          this.latlonDepartureNaar = res.HEENLATLONNAAR;
          this.latlonRetourVan = res.TERUGLATLONVAN;
          this.latlonRetourNaar = res.TERUGLATLONNAAR;
          this.reistijdheen = res.REISTIJDHEEN;
          this.reistijdterug = res.REISTIJDRETOUR;
          this.priceLoading = false;

          const pDate = new Date(this.pickupDate);
          const rDate = new Date(this.retourDate);
          const sd = this.startDate;
          let flag = false;
          if ((this.departure && this.arrival) || this.departure) {
            flag = pDate < sd;
          } else if (this.arrival) {
            flag = rDate < sd;
          }

          if ((!this.combiEnabled && flag) && this.price && this.price.PO && this.price.KORTINGPODATA && this.price.KORTINGPODATA.BETAALDDOORTRAVELAGENT) {
            if (!this.price.KORTINGPODATA.BUSINESS && !this.price.KORTINGPODATA.COMFORT && this.type !== 'COMBINATIE') {
              this.type = 'COMBINATIE';
              this.priceLoading = true;
            }
          } else if (!this.combiEnabled && this.price && !(this.price.PO && this.price.KORTINGPODATA && this.price.KORTINGPODATA.BETAALDDOORTRAVELAGENT)) {
            if (this.type === 'COMBINATIE') {
              this.type = 'COMFORT';
              this.priceLoading = true;
            }
          }

        } catch (e) {
          console.warn(e);
        }
        this.priceLoading = false;
      }
    },

    getCurrentBooking() {
      return {
        heenterug: this.heenterug,
        soortvervoer: this.type.toLowerCase(),
        taal: this.$i18n.locale.toUpperCase(),
        idbesteller: "",
        idaccount: this.account && this.account.user.id ? this.account.user.id : "",
        po: this.POnr,
        coupons: JSON.stringify(this.coupons),
        promo: this.price.KORTINGPODATA.CAMPAGNE ? this.price.KORTINGPODATA.CAMPAGNE : "",
        pax: this.pax,
        koffers: this.koffers,
        rolstoelen: this.rolstoelen,
        skis: this.skis,
        kinderwagens: this.kinderwagens,
        golfbags: this.golfbags,
        fietskoffers: this.fietskoffers,
        rollators: this.rollators,
        cargo: this.cargo,
        aantalks1: 0,
        aantalks2: this.kinderstoelenSmall,
        aantalks3: this.kinderstoelenMedium,
        aantalks4: this.kinderstoelenLarge,
        totaleprijs: this.price ? parseFloat(this.price.PRIJSKLANTHEEN) + parseFloat(this.price.PRIJSKLANTTERUG) : "",
        datumheen: this.pickupDate || "",
        uurvluchtheen: this.departureTime,
        uurheen: "00:00",
        vluchtnrheen: this.flightDeparture,
        idluchthavenheen: this.airportDeparture ? this.airportDeparture : "",
        vroegeropluchthaven: this.timeMargin,
        heenstraat: this.pickupAddress.street,
        heennr: this.pickupAddress.nr,
        heenpostnr: this.pickupAddress.postal,
        heenstad: this.departureCity,
        heenland: this.fromCountry,
        afstandheen: this.price ? this.price.AFSTANDHEEN : "",
        reistijdheen: this.reistijdheen,
        heenlatlonvan: this.latlonDepartureVan,
        heenlatlonnaar: this.latlonDepartureNaar,
        datumterug: this.retourDate || "",
        uurvluchtterug: this.retourTime,
        uurterug: this.retourTime,
        vluchtnrterug: this.flightRetour,
        idluchthaventerug: this.airportRetour ? this.airportRetour : "",
        terugherkomst: this.airportAbroadRetour || "",
        terugstraat: this.retourAddress.street,
        terugnr: this.retourAddress.nr,
        terugpostnr: this.retourAddress.postal,
        terugstad: this.retourCity,
        terugland: this.toCountry,
        afstandterug: this.price ? this.price.AFSTANDRETOUR : "",
        reistijdterug: this.reistijdterug,
        teruglatlonvan: this.latlonRetourVan,
        teruglatlonnaar: this.latlonRetourNaar,
        voornaam: this.firstName,
        naam: this.lastName,
        telklant: this.phone,
        emailklant: this.email,
        talic: this.price.KORTINGPODATA.TALIC && !this.isAgent ? this.price.KORTINGPODATA.TALIC : this.talic,
        uid: this.uid,
        emailagent: this.price.KORTINGPODATA.EMAILAGENT && !this.isAgent ? this.price.KORTINGPODATA.EMAILAGENT : this.emailagent,
        naamagent: this.price.KORTINGPODATA.NAAMAGENT && this.isAgent ? this.price.KORTINGPODATA.NAAMAGENT : this.naamagent,
        brutoprijsheen: this.price ? this.price.BRUTOPRIJSHEEN : "",
        nettoprijsheen: this.price ? this.price.NETTOPRIJSHEEN : "",
        prijsklantheen: this.price ? this.price.PRIJSKLANTHEEN : "",
        brutoprijsterug: this.price ? this.price.BRUTOPRIJSTERUG : "",
        nettoprijsterug: this.price ? this.price.NETTOPRIJSTERUG : "",
        prijsklantterug: this.price ? this.price.PRIJSKLANTTERUG : "",
        kortingcouponsheen: this.price ? this.price.KORTINGCOUPONSHEEN : "",
        kortingcouponsterug: this.price ? this.price.KORTINGCOUPONSTERUG : "",
        portal: this.isAgent ? 1 : 0
      };
    },

    async saveBooking() {
      this.paymentLoading = true;
      try {
        const res = await this.$store.dispatch('booking/saveBooking', this.getCurrentBooking());
        this.handleBookingResult(res);
      } catch (e) {
        alert(e);
        this.paymentLoading = false;
      }
    },

    async handleBookingResult(id) {
      const res = await this.$store.dispatch('booking/getBooking', id);
      if (this.checkPaymentNeeded(res)) {
        this.preparePayment(res);
        return;
      }
      this.finishFreeOrder(res[0].BONNR);
    },

    checkPaymentNeeded(booking) {
      let flag = false;
      if (booking && booking.length) {
        for (let i = 0; i < booking.length; i++) {
          if (parseFloat(booking[i].OPGAVEPRIJS) > 0) {
            flag = true;
            break;
          }
        }
      }
      return flag;
    },

    async preparePayment(booking) {
      let price = 0;
      booking.forEach(b => {
        price += parseFloat(b.OPGAVEPRIJS);
      });
      let redirectsettings = "";
      if (this.isAgent) {
        redirectsettings = `?TALIC=${this.talic}&UID=${this.uid}&TAEMAIL=${this.emailagent}&TANAME=${this.naamagent}&AGENT=true`;
      }
      const paymentSettings = PaymentService.preparePaymentData(booking[0].BONNR, booking[0].CUSTEMAIL, price, this.$i18n.locale, redirectsettings);
      const talic = this.price && this.price.KORTINGPODATA && this.price.KORTINGPODATA.TALIC ? this.price.KORTINGPODATA.TALIC : this.talic;

      if (talic && this.isAgent) {
        //payment met talic
        try {
          const response = await this.$store.dispatch('booking/getPaymentMethod', talic);
          this.paymentMode = response;
          if (this.paymentMode === 'INVOICE') {
            this.finishInvoiceOrder(booking[0].BONNR);
          } else {
            this.setupIngenicoForm(paymentSettings);
          }
        } catch (e) {
          console.warn(e);
          this.setupIngenicoForm(paymentSettings);
        }
      } else {
        this.setupIngenicoForm(paymentSettings);
      }
    },

    setupIngenicoForm(options) {
      const form = document.createElement("form");
      document.body.appendChild(form);
      form.method = "POST";
      form.action = options.actionUrl;
      form.id = "form-payment";

      const element1 = document.createElement("input");
      element1.name = "ACCEPTURL"
      element1.value = options.confirmUrl;
      element1.type = 'hidden'
      form.appendChild(element1);

      const element2 = document.createElement("input");
      element2.name = "AMOUNT"
      element2.value = options.price;
      element2.type = 'hidden'
      form.appendChild(element2);

      const element3 = document.createElement("input");
      element3.name = "CANCELURL"
      element3.value = options.cancelUrl;
      element3.type = 'hidden'
      form.appendChild(element3);

      const element4 = document.createElement("input");
      element4.name = "CURRENCY"
      element4.value = options.currency;
      element4.type = 'hidden'
      form.appendChild(element4);

      const element5 = document.createElement("input");
      element5.name = "DECLINEURL"
      element5.value = options.failUrl;
      element5.type = 'hidden'
      form.appendChild(element5);

      const element6 = document.createElement("input");
      element6.name = "EMAIL"
      element6.value = options.email;
      element6.type = 'hidden'
      form.appendChild(element6);

      const element7 = document.createElement("input");
      element7.name = "EXCEPTIONURL"
      element7.value = options.failUrl;
      element7.type = 'hidden'
      form.appendChild(element7);

      const element8 = document.createElement("input");
      element8.name = "LANGUAGE"
      element8.value = options.lang;
      element8.type = 'hidden'
      form.appendChild(element8);

      const element9 = document.createElement("input");
      element9.name = "ORDERID"
      element9.value = options.id;
      element9.type = 'hidden'
      form.appendChild(element9);

      const element10 = document.createElement("input");
      element10.name = "PSPID"
      element10.value = options.pspid;
      element10.type = 'hidden'
      form.appendChild(element10);

      const element11 = document.createElement("input");
      element11.name = "SHASIGN"
      element11.value = options.sign;
      element11.type = 'hidden'
      form.appendChild(element11);

      form.submit();
    },

    async finishFreeOrder(bonnr) {
      const res = await this.$store.dispatch('booking/handleFreeOrder', bonnr);
      this.$router.push(res.redirect);
    },

    async finishInvoiceOrder(id) {
      const res = await this.$store.dispatch('booking/handleInvoice', id);
      this.$router.push(res.redirect);
    },

    mailValidate(email) {
      const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return email.match(regex);
    },

    resetValid() {
      this.airportDepartureValid = true;
      this.departureCityValid = true;
      this.pickupDateValid = true;
      this.flightDepartureValid = true;
      this.departureStreetValid = true;
      this.departureNrValid = true;

      this.airportRetourValid = true;
      this.airportAbroadRetourValid = true;
      this.retourCityValid = true;
      this.retourDateValid = true;
      this.flightRetourValid = true;
      this.retourStreetValid = true;
      this.retourNrValid = true;

      this.firstNameValid = true;
      this.lastNameValid = true;
      this.phoneInputValid = true;
      this.mailValid = true;

      this.gdprValid = true;
      this.termsValid = true;
      this.consentValid = true;
    },

    checkValid() {
      const pDate = new Date(this.pickupDate);
      const rDate = new Date(this.retourDate);
      //departure
      this.airportDepartureValid = this.airportDeparture;
      this.departureCityValid = this.pickupAddress.postal && this.dCities;
      this.pickupDateValid = this.arrival ? this.pickupDate && (pDate <= rDate) : this.pickupDate;
      this.flightDepartureValid = this.flightDeparture && this.flightDeparture !== "";
      this.departureStreetValid = this.pickupAddress.street && this.departureStreetArray && this.departureStreetArray.find(s => s['STRAAT'].toLowerCase() === this.pickupAddress.street.toLowerCase());
      this.departureNrValid = this.pickupAddress.nr && this.pickupAddress.nr !== "";
      this.departureValid = this.airportDepartureValid && this.departureCityValid && this.pickupDateValid && this.flightDepartureValid && this.departureStreetValid && this.departureNrValid;
      //ret
      this.airportRetourValid = this.airportRetour;
      this.retourCityValid = this.retourAddress.postal && this.rCities;
      this.retourDateValid = this.departure ? this.retourDate && (pDate <= rDate) : this.retourDate;
      this.airportAbroadRetourValid = this.airportAbroadRetour && this.airportAbroadRetour !== "";
      this.flightRetourValid = this.flightRetour && this.flightRetour !== "";
      this.retourStreetValid = this.retourAddress.street && this.retourStreetArray && this.retourStreetArray.find(s => s['STRAAT'].toLowerCase() === this.retourAddress.street.toLowerCase());
      this.retourNrValid = this.retourAddress.nr && this.retourAddress.nr !== "";

      this.retourValid = this.airportRetourValid && this.retourCityValid && this.retourDateValid && this.flightRetourValid && this.retourStreetValid && this.retourNrValid && this.airportAbroadRetourValid;

      this.firstNameValid = this.firstName && this.firstName !== "";
      this.lastNameValid = this.lastName && this.lastName !== "";
      this.phoneInputValid = this.phone && this.phoneValid;
      this.mailValid = this.mailValidate(this.email);
      this.gdprValid = this.gdprCompliant;
      this.termsValid = this.termsCompliant;
      this.consentValid = this.consentCompliant;
      this.personValid = this.firstNameValid && this.lastNameValid && this.phoneInputValid && this.mailValid && this.gdprValid && this.termsValid && this.consentValid;
    },

    onDepartureAirportChanged() {
      if (this.currentStep === 0) {
        this.airportRetour = this.airportDeparture;
      }
    },

    setCargoProgress() {
      const bar = document.getElementById('progress-inner');
      if (this.cargo && bar) {
        let percentage = (this.cargo / this.MAX_CARGO) * 100;

        percentage = percentage > 100 ? 100 : percentage;
        bar.style.width = percentage + '%';
      }
    },

    formValidate() {
      this.resetValid();
      this.checkValid();
      const currentStep = this.currentStep;
      if (currentStep === 0) {
        let dateValid = this.departure ? this.pickupDateValid : this.retourDateValid;
        return this.departureCityValid && this.airportDepartureValid && dateValid;
      } else if (currentStep === 1) {
        let heenterug = this.heenterug;

        if (heenterug === "HT") {
          return this.retourValid && this.departureValid;
        } else if (heenterug === "H") {
          return this.departureValid;
        } else if (heenterug === "T") {
          return this.retourValid;
        }
      } else if (currentStep === 2) {
        return this.cargoValid;
      } else if (currentStep === 3) {
        return this.personValid && this.gdprCompliant && this.termsCompliant;
      }
    },

    handleNumberInput(event, key) {
      const max = parseInt(event.target.getAttribute('max'));
      const min = parseInt(event.target.getAttribute('min'));

      let value = event.target.value;
      if (!parseInt(value)) {
        this[key] = min;
      } else {
        if (parseInt(value) > max) {
          this[key] = max;
        }
        if (parseInt(value) < min) {
          this[key] = min;
        }
      }
    },

    onRetourFocused() {
      this.retourFocused = true;
      this.retourStreetsVisible = true;
    },

    onSelectDepartureStreet(street) {
      this.pickupAddress.street = street.STRAAT;
      this.departureCity = street.STAD;
      if (!this.retourFocused) {
        this.retourCity = street.STAD;
      }
      this.departureStreetsVisible = false;
    },
    onSelectRetourStreet(street) {
      this.retourAddress.street = street.STRAAT;
      this.retourCity = street.STAD;
      this.retourStreetsVisible = false;
    },

    postalDepartureChanged(event) {
      if(event && event.keyCode === 9){
        return;
      }
      this.departureCityValid = true;
      this.departureCity = null;
      if (!this.retourFocused) {
        this.retourCity = null;
      }
      this.pickupAddress.street = "";
      this.pickupAddress.nr = "";
    },

    postalRetourChanged(event) {
      if(event && event.keyCode === 9){
        return;
      }
      this.retourCityValid = true;
      this.retourCity = null;
      this.retourAddress.street = "";
      this.retourAddress.nr = "";
    },

    postalCTAChanged(event) {
      this.postalDepartureChanged(event);
      this.postalRetourChanged(event);
    },

    sameAddressClicked() {
      this.retourAddress = {...this.pickupAddress};
      this.retourCity = this.departureCity;
      this.retourFocused = false;
      this.locationsChanged();
    },

    onConfirmClicked() {
      if (this.formValidate() && (!this.koffers || (this.heenterug == "HT" && this.pickupDate == this.retourDate))) {
        this.bookingWarning = {
          koffers: !this.koffers,
          dates: this.heenterug == "HT" && this.pickupDate == this.retourDate
        };
      } else if (this.formValidate()) {
        this.saveBooking();
      }
    },

    onNextClicked() {
      const stepValid = this.formValidate();
      this.formValid = stepValid;
      if (stepValid) {
        if (!this.couponValid) {
          this.coupon = "";
        }
        this.currentStep++;
        if (this.currentStep === 2) {
          setTimeout(() => {
            this.setCargoProgress();
          }, 0);
        }
        this.resetValid();
      }

    },

    onPreviousClicked() {
      if (!this.couponValid) {
        this.coupon = "";
      }
      if (this.currentStep) {
        this.currentStep--;
      }
    },

    handlePhoneValidate(value) {
      this.phoneValid = value.valid && value.type === "mobile";
    },

    onBookingConfirmed() {
      this.bookingWarning = false;
      this.saveBooking();
    },

    onBookingDeclined() {
      this.bookingWarning = false;
    },

    onShowPickupAddresses() {
      this.isPickupPopupVisible = true;
    },
    onShowRetourAddresses() {
      this.isRetourPopupVisible = true;
    },

    onSelectAccountPickup(item) {
      const pickup = this.pickupAddress;

      if (pickup.postal !== item.zip) {
        this.departureCity = '';
      }

      pickup.street = item.street;
      pickup.postal = item.zip;
      pickup.nr = item.streetnr;
      this.isPickupPopupVisible = false;
      this.locationsChanged();
    },

    onSelectAccountRetour(item) {
      const retour = this.retourAddress;
      if (retour.postal !== item.zip) {
        this.retourCity = '';
      }
      retour.street = item.street;
      retour.postal = item.zip;
      retour.nr = item.streetnr;
      this.retourFocused = true;
      this.isRetourPopupVisible = false;
      this.locationsChanged();
    },

    removeCoupon(id) {
      this.coupons.splice(id, 1);
    },


    //picker limits
    eighthoursbefore(date) {
      const td = this.today;
      date.setHours(td.getHours(), td.getMinutes(), 0, 0);
      const limit = new Date(this.limit.getTime());
      limit.setHours(0, 0, 0, 0);
      return date < limit;
    },

    eighthoursbeforeandpickup(date) {
      const pu = new Date(this.pickupDate);
      if (this.departure) {
        return date < new Date(pu);
      }
      const td = this.today;
      date.setHours(td.getHours(), td.getMinutes(), 0, 0);
      const limit = new Date(this.limit.getTime());
      limit.setHours(0, 0, 0, 0);
      return date < limit;
    },

    eighthoursbeforeflight(hour) {
      const pu = new Date(this.pickupDate);
      hour.setDate(pu.getDate());
      hour.setMonth(pu.getMonth());
      hour.setFullYear(pu.getFullYear());
      hour.setSeconds(0, 0);
      const limit = new Date(this.limit.getTime());
      limit.setSeconds(0, 0);
      return hour < limit
    },

    eighthoursbeforeflightretour(hour) {
      const rd = new Date(this.retourDate);
      hour.setDate(rd.getDate());
      hour.setMonth(rd.getMonth());
      hour.setFullYear(rd.getFullYear());
      hour.setSeconds(0, 0);
      const limit = new Date(this.limit.getTime());
      limit.setSeconds(0, 0);
      return hour < limit
    },

    twodaysbefore(date) {
      const td = new Date();
      td.setHours(0, 0, 0, 0);
      return date < new Date(td.getTime() + 2 * 24 * 3600 * 1000);
    },

    twodaysbeforeandpickup(date) {
      const pu = new Date(this.pickupDate);
      pu.setHours(0, 0, 0, 0);
      if (this.departure) {
        return date < new Date(pu);
      }
      const td = new Date();
      td.setHours(0, 0, 0, 0);
      return date < new Date(td.getTime() + 2 * 24 * 3600 * 1000);
    },

    checkCombiEnabled() {
      const pDate = new Date(this.pickupDate);
      const rDate = new Date(this.retourDate);
      const sd = this.startDate;
      this.combiEnabled = true;
      if ((this.departure && this.arrival) || this.departure) {
        this.combiEnabled = !(pDate < sd || pDate >= this.combiDeath);
      } else if (this.arrival) {
        this.combiEnabled = !(rDate < sd || rDate >= this.combiDeath);
      }
    }
  }
}
</script>