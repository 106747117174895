<template>
    <div class="booking__steps">
        <div class="booking__steps__container">
            <div class="booking__steps__progress">
                <div id="progress-steps-inner" class="booking__steps__progress__bar"></div>
            </div>
            <div class="booking__steps__orb" :class="['booking__steps__orb--'+item,{'booking__steps__orb--active':item<=currentStep}]" v-for="item in maxStep"
                 :key="item"></div>
            <p class="booking__steps__label booking__steps__label--1">{{$t('booking.addressinfo')}}</p>
            <p class="booking__steps__label booking__steps__label--2">{{$t('booking.extras')}}</p>
            <p class="booking__steps__label booking__steps__label--3">{{$t('booking.personalia')}}</p>
            <p class="booking__steps__label booking__steps__label--4">{{$t('booking.payment')}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BookingSteps",

        props: {
            currentStep: {required: true},
            maxStep: {required: true}
        },

        created(){
            this.setProgress();
        },

        watch: {
            currentStep() {
                this.setProgress();
            }
        },

        methods:{
            setProgress(){
                const progress = document.getElementById('progress-steps-inner');
                if (progress) {
                    let c = this.currentStep;
                    let s = this.maxStep;
                    const perc = (c - 1) / (s - 1) * 100;
                    progress.style.width = perc + '%';
                }
            }
        }
    }
</script>